import React from "react";
import { Wizard as WizardComponent, Steps, Step } from "react-albus";

import { analyticsEvent } from "modules/analytics";
import { Typography, Card } from "components";

import { steps } from "./Steps";
import * as S from "./styles";

function Wizard() {
  return (
    <WizardComponent
      render={(wizard) => {

        const wizardIndex = wizard.history.index;
        const wizardTotal = wizard.steps.length - 1;
        const percentage = ((wizardIndex + 1) * 100) / wizardTotal;

        analyticsEvent({
          action: 'page_view',
          page: wizard.step.analytics_id
        });

        return (
          <>
            {wizard.step.progressBar && <S.Progress value={percentage} max="100"/>}

            <Card>
              {wizard.step.logo && <S.Logo />}

              {/* {wizardIndex > 0 && wizardIndex < 5 && (
                  <BackButton onClick={wizard.previous} />
                )} */}

              {/* {wizardIndex > 0 && wizardIndex < 5 && <CancelButton />} */}

              {/* {wizard.step.icon && <S.Icon src={wizard.step.icon} />} */}

              {wizard.step.title && (
                <Typography.H1>{wizard.step.title}</Typography.H1>
              )}

              {wizard.step.subTitle && (
                <Typography.H2>{wizard.step.subTitle}</Typography.H2>
              )}

              <Steps>
                {steps.map((step) => (
                  <Step key={step.id} {...step} />
                ))}
              </Steps>
            </Card>
          </>
        );
      }}
    />
  );
}

export default Wizard;
