import * as yup from "yup";

export default yup.object({
  numero_os: yup
    .string()
    .matches(/^[0-9]{7}-[0-9]{2}$/, "OS inválida")
    .required("Campo obrigatório"),
  cpf: yup
    .string()
    .matches(/^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/, "CPF inválido")
    .required("Campo obrigatório"),
});
