import React, { useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import toast from "react-hot-toast";

import { getToken, validateToken } from "services/api";

import { useAuthentication } from "modules/authentication";
import { analyticsEvent } from "modules/analytics";
import { chatEvent } from "modules/chat";

import { Button } from "components";

import * as S from "./styles";

function Validation(props) {

  const { store, setOrderData } = useAuthentication();

  const [loading, setLoading] = useState(false);
  const [validating, setValidating] = useState(false);
  const [token, setToken] = useState(false);

  async function doValidateToken() {

    analyticsEvent({
      action: 'click',
      category: 'button',
      label: 'Validar'
    });

    if(token) {

      try {

        setValidating(true);

        const response = await validateToken(token, store.uuid);

        if(response.status !== 200 || !response.data.passed) throw response;

        props.push("options");
        
      } catch (error) {
        
        let message = "Ocorreu um erro. Tente novamente.";

        if(error?.response?.data?.message) {
          message = error.response.data.message;
        }

        if(error?.response?.data?.type === "@data/not-found") {
          message = "Sua sessão expirou.";
          setTimeout(() => document.location = process.env.REACT_APP_BASE_URL, 9000);
        }
        
        toast.error(message, {
          duration: 8000,
        });

        analyticsEvent({
          action: 'exception',
          description: message,
          fatal: false
        });

        setValidating(false);

      } finally {
        // setValidating(false);
      }

    } else {

      const message = "Preencha o token";

      toast.error(message, {
        duration: 8000,
      });

      analyticsEvent({
        action: 'exception',
        description: message,
        fatal: false
      });

    }
  }

  async function doGetToken() {

    try {

      analyticsEvent({
        action: 'click',
        category: 'button',
        label: 'Enviar novamente'
      });

      setLoading(true);

      const {numero_os, cpf} = store;

      const response = await getToken({numero_os, cpf});

      if(response.status !== 200 || !response.data.uuid) throw response;

      setOrderData({ ...response.data });

      const cellphone = (response?.data?.cellphone) ? ` para ${response.data.cellphone}` : "";

      toast.success(`Token enviado${cellphone}.`, {
        duration: 8000,
      });

    } catch (error) {

      //console.warn('auth:error', {error});
      let message = "Ocorreu um erro. Tente novamente.";
      
      if(error?.response?.data?.message) {
        message = error.response.data.message;
      }

      toast.error(message, {
        duration: 8000,
      });

      analyticsEvent({
        action: 'exception',
        description: message,
        fatal: false
      });

    } finally {
      setLoading(false);
    }

  }

  function handleChat() {

    chatEvent({
      uuid: store.uuid,
      origin: 'Token'
    });

  }

  return (
    <>
      <S.Center>
        <ReactCodeInput
          fields={5}
          type="text"
          onChange={value => setToken(value.length === 5 ? value : false)}
          //onComplete={(value) => setToken(value)}
          placeholder={["", "", "", "", ""]}
          loading={validating}
        />
      </S.Center>

      <Button
        disabled={!token || loading}
        isLoading={validating}
        onClick={doValidateToken}
      >
        Validar
      </Button>

      <Button
        disabled={validating}
        isLoading={loading}
        onClick={doGetToken}
        fill="outline"
        mt="10px"
      >
        Enviar novamente
      </Button>

      <S.Chat>Caso esteja tendo dificuldade com o Token, <a href onClick={handleChat}>clique aqui</a> e fale com o nosso chat.</S.Chat>
    </>
  );
}

export default Validation;
